import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import bg from "../assets/bg.png";

import { connect } from "../redux/blockchain/blockchainActions";
import { useSelector } from "react-redux";
import { fetchData } from "../redux/data/dataActions";

const Minting = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.data);
  const blockchain = useSelector((state) => state.blockchain);

  const [claimingNFT, setNFTClaimed] = useState(false);
  const [isConnected, setConnected] = useState(false);

  const [buyQuantity, setBuyQuantity] = useState(1);
  const [dataFetched, setDataFetched] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [connectBtnText, setConnectBtnText] = useState("Connect Wallet");
  const [displayMsg, setDisplayMsg] = useState("");

  const toastMessage = (message) => toast(message);

  const [loadedConfig, setLoadedConfig] = useState(null);

  useEffect(() => {
    (async function () {
      await fetch("/config/config.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (response) => {
          const config = await response.json();
          setLoadedConfig(config);
          setDataFetched(1);
        })
        .catch((e) => {
          console.log(e);
        });
    })();
  }, []);

  const handleConnect = (e) => {
    e.preventDefault();
    if (blockchain.account === null) {
      setLoading(true);
      !isConnected && setConnectBtnText("Connecting...");
      dispatch(connect()).then((response) => {
        if (response !== undefined) {
          setConnected(false);
          setLoading(false);
          setConnectBtnText("Connect Wallet");
          toastMessage(response.payload);
        }
      });
      getData();
    }
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
      setConnected(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  useEffect(() => {
    displayMsg !== "" && toastMessage(displayMsg);
  }, [displayMsg]);

  const handleBuyQuantityDecrement = () => {
    buyQuantity > 1 && setBuyQuantity(buyQuantity - 1);
  };

  const handleBuyQuantityIncrement = () => {
    buyQuantity < loadedConfig.MAX_BUY_QUANTITY &&
      setBuyQuantity(buyQuantity + 1);
  };

  const handleBuyNFT = (e) => {
    e.preventDefault();
    claimNFTs();
    setLoading(true);
    getData();
  };

  const claimNFTs = () => {
    let cost = loadedConfig.WEI_COST;
    let gasLimit = loadedConfig.GAS_LIMIT;
    let totalCostWei = String(cost * buyQuantity);
    let totalGasLimit = String(gasLimit * buyQuantity);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setDisplayMsg(`Minting your ${loadedConfig.NFT_NAME}...`);
    setNFTClaimed(true);
    console.log(loadedConfig.CONTRACT_ADDRESS);
    console.log(blockchain.account);
    console.log(totalCostWei);
    var gasAmount = blockchain.smartContract.methods
      .mint(buyQuantity)
      .estimateGas({
        to: loadedConfig.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .then((estimatedGas, err) => {
        console.log("----------------");
        console.log(err);
        console.log("----------------");

        console.log(gasAmount);
        estimatedGas = Math.round(estimatedGas * 1.2);
        console.log("gas limit estimation = " + estimatedGas + " units");
        console.log({
          from: loadedConfig.CONTRACT_ADDRESS,
          value: totalCostWei,
        });

        blockchain.smartContract.methods
          .mint(buyQuantity)
          .send({
            to: loadedConfig.CONTRACT_ADDRESS,
            from: blockchain.account,
            value: totalCostWei,
            gas: estimatedGas,
          })
          .once("error", (err) => {
            console.log(err);
            setDisplayMsg(
              "Sorry, something went wrong please try again later."
            );
            setNFTClaimed(false);
          })
          .then((receipt) => {
            console.log(receipt);
            setDisplayMsg(`WOW, the ${loadedConfig.NFT_NAME} is yours!`);
            setNFTClaimed(false);
            setLoading(false);
            dispatch(fetchData(blockchain.account));
          });
      })
      .catch(function (error) {
        console.log("Estimategas() - catch error");
        console.log(error);

        if (error.message.includes("max NFT per address")) {
          setDisplayMsg(
            `Error: Max ${loadedConfig.MAX_BUY_QUANTITY} NFT per address allowed!`
          );
          setNFTClaimed(false);
        }
        if (error.message.includes("insufficient funds")) {
          setDisplayMsg(`Error: Insufficient funds for gas + price`);
          setNFTClaimed(false);
        }
      });
  };

  return (
    <div
      style={{
        height: "100vh",
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        theme="dark"
        toastStyle={{
          backgroundColor: "rgba(var(--dark-rgb), 0.5)",
          border: "1px solid rgba(var(--secondary-rgb), 0.5)",
          color: "var(--dark)",
          borderRadius: 10,
          textAlign: "center",
        }}
        rtl={false}
        draggable
        pauseOnHover
      />
      {dataFetched === 1 && (
        <div className="centered">
          <div className="mint-container">
            <div className="content">
              <div className="title">{loadedConfig.NFT_NAME}</div>
              <br />
              <div className="qty">
                {data.totalSupply} / {loadedConfig.MAX_SUPPLY}
              </div>
              <br />
              <a
                href={loadedConfig.SCAN_LINK}
                target="_blank"
                rel="noreferrer"
                style={{ all: "unset" }}
              >
                <div
                  style={{
                    letterSpacing: 2,
                    fontWeight: 600,
                    cursor: "pointer",
                  }}
                >
                  {loadedConfig.CONTRACT_ADDRESS.substring(0, 15)}...
                </div>
              </a>
              {data.totalSupply >= loadedConfig.MAX_SUPPLY ? (
                <div>
                  <h1>The sale has ended.</h1>
                  <h3>You can still find {loadedConfig.NFT_NAME} on</h3>
                  <a
                    target={"_blank"}
                    href={loadedConfig.MARKETPLACE_LINK}
                    rel="noreferrer"
                  >
                    <h3>{loadedConfig.MARKETPLACE}</h3>
                  </a>
                </div>
              ) : (
                <div>
                  <div className="cost">
                    Mint {buyQuantity}{" "}
                    <span style={{ color: "var(--secondary)" }}>
                      {loadedConfig.NFT_NAME}
                    </span>{" "}
                    for{" "}
                    <span style={{ color: "var(--secondary)" }}>
                      {Math.round(
                        (loadedConfig.DISPLAY_COST * buyQuantity +
                          Number.EPSILON) *
                          1000
                      ) / 1000}{" "}
                      {loadedConfig.NETWORK.SYMBOL}
                    </span>
                  </div>
                  <div style={{ color: "var(--light)" }}>
                    {isConnected ? (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <button
                            disabled={claimingNFT ? true : false}
                            onClick={handleBuyQuantityDecrement}
                            className="plus-minus-btn"
                          >
                            <div>-</div>
                          </button>
                          <div className="buy-qty-text">{buyQuantity}</div>
                          <button
                            disabled={claimingNFT ? true : false}
                            onClick={handleBuyQuantityIncrement}
                            className="plus-minus-btn"
                          >
                            <div>+</div>
                          </button>
                        </div>
                        <center>
                          <br />
                          <button
                            disabled={claimingNFT ? true : false}
                            onClick={handleBuyNFT}
                            className="primary-btn"
                            style={{ width: "200px" }}
                          >
                            {claimingNFT ? "Minting..." : "Mint Now"}
                          </button>
                        </center>
                      </div>
                    ) : (
                      <div>
                        <center>
                          <div
                            style={{
                              textTransform: "uppercase",
                              fontSize: 12,
                              letterSpacing: 2,
                            }}
                          >
                            Excluding gas fees
                          </div>
                          <hr />
                          <h5
                            style={{
                              fontSize: 14,
                              letterSpacing: 2,
                            }}
                          >
                            Connect to the {loadedConfig.NETWORK.NAME} network
                          </h5>
                          <button
                            onClick={handleConnect}
                            disabled={isLoading}
                            className="primary-btn"
                            style={{ marginTop: 20 }}
                          >
                            {connectBtnText}
                          </button>
                        </center>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="mt-4 hstack justify-content-center social-btns">
                <a
                  href="https://discord.gg/c8FwpmV3JM"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="bi bi-discord"></i>
                </a>
                <a
                  href="https://twitter.com/OnejungleOrg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="bi bi-twitter"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Minting;
